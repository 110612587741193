import React, { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { Card, Table, Button, Modal, Form, Alert, Spinner } from "react-bootstrap";
import "./Orders.css";

// Helper function to format Firestore timestamps
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp._seconds) {
    const date = new Date(timestamp._seconds * 1000);
    return date.toLocaleDateString();
  }
  return '';
};

// Helper function to format price
const formatPrice = (price) => {
  return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

const Orders = () => {
  const { currentUser } = useAuth();
  const [orders, setOrders] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [originalOrders, setOriginalOrders] = useState([]);
  const [showAlert, setShowAlert] = useState({ show: false, message: '', variant: '' });
  const [loading, setLoading] = useState(false);


  const fetchOrdersAndPrinters = useCallback(async () => {
    if (!currentUser) return { orders: [], printers: [] };
  
    const token = currentUser.stsTokenManager.accessToken;
  
    try {
      // Fetch all orders with cache-busting
      const orderResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pairbo/order/print-client-orders?t=${Date.now()}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let orders = orderResponse.data;
      orders = orders.sort((a, b) => b.orderCreatedAt._seconds - a.orderCreatedAt._seconds);
  
      // Fetch printers with cache-busting
      const printerResponse = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/pairbo/printer/printer-details?t=${Date.now()}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const printers = printerResponse.data;
  
      // Update states
      setOriginalOrders(orders);
      setOrders(orders);
      setPrinters(printers);
  
      return { orders, printers }; // Return fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchOrdersAndPrinters();

      // Set interval to fetch orders every 1 hour (3600000 milliseconds)
      const intervalId = setInterval(fetchOrdersAndPrinters, 3600000);

      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [currentUser, fetchOrdersAndPrinters]);

  const handlePrint = (orderId) => {
    setSelectedOrder(orderId);
    setShowModal(true);
  };

  const handleSelectPrinter = (printerId) => {
    setSelectedPrinter(printerId);
  };

  const handleConfirmPrint = async () => {
    if (selectedPrinter) {
      setLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/pairbo/cards/print`,
          {
            orderId: String(selectedOrder),
            printerId: String(selectedPrinter),
          },
          {
            headers: { Authorization: `Bearer ${currentUser.stsTokenManager.accessToken}` },
          }
        );
        setShowAlert({ show: true, message: 'Print request sent successfully!', variant: 'success' });
      } catch (error) {
        setShowAlert({ show: true, message: 'Failed to send print request. Please try again.', variant: 'danger' });
        console.error("Error sending print request:", error);
      } finally {
        setLoading(false);
      }

      setShowModal(false);

      setTimeout(() => {
        setShowAlert({ show: false, message: '', variant: '' });
      }, 4000);
    }
  };


  const handleSearchClick = () => {
    const trimmedQuery = searchQuery.trim();

    if (trimmedQuery) {
      const filteredOrders = originalOrders.filter((order) => {
        const orderIdStr = String(order.orderId).trim();
        return orderIdStr === trimmedQuery;
      });

      setOrders(filteredOrders);
    } else {
      setOrders(originalOrders);
    }
  };



  const handleRefresh = async () => {
    setLoading(true);
  
    try {
      // Fetch new data and get the latest orders
      const { orders: newOrders } = await fetchOrdersAndPrinters();
  
      // Reapply current search filter
      const trimmedQuery = searchQuery.trim();
      if (trimmedQuery) {
        const filteredOrders = newOrders.filter(order => 
          String(order.orderId).trim() === trimmedQuery
        );
        setOrders(filteredOrders);
      }
  
      setShowAlert({ show: true, message: 'Orders refreshed successfully!', variant: 'success' });
      setTimeout(() => setShowAlert({ show: false }), 2000);
    } catch (error) {
      setShowAlert({ show: true, message: 'Failed to refresh orders. Please try again.', variant: 'danger' });
      console.error("Error refreshing orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() === '') {
      setOrders(originalOrders);
    }
  };

  return (
    <div className="container mt-5 pt-4">
      <div className="mb-4 d-flex">
        <Form.Control
          type="text"
          placeholder="Search by Shopify Order ID..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          style={{ border: "2px solid #ccc", borderRadius: "4px", padding: "10px", flex: 1 }}
        />
        <Button
          variant="primary"
          onClick={handleSearchClick}
          style={{ marginLeft: "10px" }}
        >
          Search
        </Button>
        <Button
          variant="secondary"
          onClick={handleRefresh}
          style={{ marginLeft: "10px" }}
          disabled={loading} // Disable button when loading
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Refresh'
          )}
        </Button>

      </div>

      <Alert show={showAlert.show} variant={showAlert.variant} onClose={() => setShowAlert({ show: false })} dismissible>
        {showAlert.message}
      </Alert>

      <Card className="mb-4 shadow-sm" bg="dark" text="white">
        <Card.Header as="h3" className="text-center bg-dark text-white">Orders</Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive className="table-custom text-white">
            <thead className="thead-dark">
              <tr>
                <th className="text-center">Shopify Order ID</th>
                <th className="text-center">Date Created</th>
                <th className="text-center">Order Total</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order, index) => (
                  <tr key={index}>
                    <td className="text-center">{order.orderId}</td>
                    <td>{formatTimestamp(order.orderCreatedAt)}</td>
                    <td className="text-center">
                      <div className="price-wrapper">${formatPrice(order.totalPrice)}</div>
                    </td>
                    <td className="text-center">
                      <div className="d-grid gap-10 col-4  mx-auto">
                        <button type="button" className="btn btn-primary" onClick={() => handlePrint(order.orderId)}>Print</button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">No orders found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Select Printer Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formPrinterSelect">
            <Form.Label>Choose Printer Location</Form.Label>
            <div className="d-flex flex-wrap">
              {printers.map(printer => (
                <Button
                  key={printer.id}
                  variant={selectedPrinter === printer.id ? "primary" : "outline-primary"}
                  className="m-2 location-btn"
                  onClick={() => handleSelectPrinter(printer.id)}
                >
                  {printer.location}
                </Button>
              ))}
            </div>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)} style={{ width: "80px", fontSize: "17px", marginLeft: "10px" }}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirmPrint}
            disabled={!selectedPrinter}
            style={{ width: "80px", marginLeft: "auto", fontSize: "17px" }}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Print"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Orders;
